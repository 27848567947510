import i18n from '@/lang';
import moment from 'moment';
export function dateFilter(date) {
  var datetime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  try {
    return date ? moment(date).format(i18n.t(datetime ? 'format.datetime' : 'format.date')) : ' - ';
  } catch (e) {
    return date;
  }
}
export function durationFilter(time_diff) {
  // get total seconds between the times
  var delta = Math.abs(time_diff) / 1000;

  // calculate (and subtract) whole days
  var days = Math.round(delta / 86400); // "starts in 7 days" instead of 6 when playlist just finished but "starts in 2 days" instead of 3 if today is wednesday and playlist starts on friday
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  var hours = Math.round(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  var minutes = Math.ceil(delta / 60) % 60; // Ceil because of "starts in 2 minutes" instead of 1 because after that 1 minute has finished the countdown starts
  delta -= minutes * 60;
  if (days > 0) return i18n.t(days == 1 ? 'duration_day' : 'duration_days', {
    days: days
  });
  if (hours > 0) return i18n.t(hours == 1 ? 'duration_hour' : 'duration_hours', {
    hours: hours
  });
  if (minutes > 0) return i18n.t(minutes == 1 ? 'duration_minute' : 'duration_minutes', {
    minutes: minutes
  });
  return '-';
}
export function mediaDurationFilter(seconds) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'mm:ss';
  return moment().startOf('day').seconds(seconds ? seconds : 0).format(format);
}