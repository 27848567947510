import "/opt/eazyads-player/node_modules/core-js/modules/es.array.iterator.js";
import "/opt/eazyads-player/node_modules/core-js/modules/es.promise.js";
import "/opt/eazyads-player/node_modules/core-js/modules/es.object.assign.js";
import "/opt/eazyads-player/node_modules/core-js/modules/es.promise.finally.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import './styles/element-variables.scss';
import '@/styles/index.scss'; // global css

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import '@capacitor/core';
import '@procot/webostv/webOSTV-dev';
import { getCookie } from '@/utils/cookie-helper';
import './permission'; // permission control
import './icons'; // icon

Vue.config.productionTip = false;

// -- Global filters
import * as filters from './filters'; // global filters
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
// --

import _i18n from './lang';
import Element from 'element-ui';
Vue.use(Element, {
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  },
  size: getCookie('size') || 'medium' // set element-ui default size
});

new Vue({
  router: router,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');