import _objectSpread from "/opt/eazyads-player/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import deLocale from './translations/de';
import enLocale from './translations/en';
import elementEnLocale from 'element-ui/lib/locale/lang/en';
import elementDeLocale from 'element-ui/lib/locale/lang/de';
var messages = {
  de: _objectSpread(_objectSpread({}, deLocale), elementDeLocale),
  en: _objectSpread(_objectSpread({}, enLocale), elementEnLocale)
};
import { getCookie } from '@/utils/cookie-helper';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
export var available_locales = Object.keys(messages);
Vue.use(VueI18n);
export var defaultLang = function defaultLang() {
  return getCookie('lang') || navigator.language.split('-')[0];
};
var i18n = new VueI18n({
  locale: defaultLang(),
  // Browser language (de-DE),
  fallbackLocale: 'en',
  // if non-existent, fallback to english
  messages: messages
});
export default i18n;