var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "app"
    }
  }, [_c("div", {
    staticStyle: {
      height: "1in",
      width: "1in",
      left: "100%",
      position: "fixed",
      top: "100%"
    },
    attrs: {
      id: "dpi"
    }
  }), _c("router-view")], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };