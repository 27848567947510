export function getScreenSize() {
  return calcRealSize(screen.width, screen.height);
}
export function getWindowSize() {
  var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  var h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  return calcRealSize(w, h);
}
export function calcRealSize(w_in_px, h_in_px) {
  var dst_unit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'in';
  // see App.vue => <div id="dpi" style="height: 1in; width: 1in; left: 100%; position: fixed; top: 100%;"></div>
  document.getElementById('dpi').style.width = '1' + dst_unit;
  document.getElementById('dpi').style.height = '1' + dst_unit;
  var dpi_x = document.getElementById('dpi').offsetWidth;
  var dpi_y = document.getElementById('dpi').offsetHeight;
  var width = w_in_px / dpi_x;
  var height = h_in_px / dpi_y;
  var diagonale = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2));
  return {
    diagonal: diagonale,
    unit: dst_unit,
    width_in_px: w_in_px,
    height_in_px: h_in_px,
    dpi_x: dpi_x,
    dpi_y: dpi_y
  };
}
export function getOrientation() {
  var orientation = screen.msOrientation || screen.mozOrientation || (screen.orientation || {}).type;
  if (orientation === undefined) {
    return window.innerHeight > window.innerWidth ? 'portrait' : 'landscape';
  } else {
    return orientation;
  }
}