import "core-js/modules/es.array.join.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.string.starts-with.js";
import Vue from 'vue';
import VueRouter from 'vue-router';
import { defaultLang, available_locales } from '@/lang';
Vue.use(VueRouter);
var None = {
  render: function render(c) {
    return c('router-view');
  }
};
var routes = [{
  path: '/',
  redirect: function redirect(e) {
    return '/' + defaultLang() + '';
  }
}, {
  path: '/:lang(' + available_locales.join('|') + ')',
  component: function component() {
    return import('@/views/main');
  },
  hidden: true
}, {
  path: '/:all(.*)?',
  redirect: function redirect(e) {
    return '/redirect/' + defaultLang() + '/:all';
  }
}];
var router = new VueRouter({
  // for WebOS it is important, that file:/// will work => only use history-mode in http(s)
  mode: window.location.protocol.startsWith("file") ? undefined : 'history',
  // base: window.location.protocol.startsWith("file") ? '' : undefined,
  routes: routes
});
export default router;