import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import { getCookie, setCookie, removeCookie } from '@/utils/cookie-helper';
var TokenKey = 'Admin-Token';
var suffix = function suffix() {
  // -- Dev: Multiple screens in iframes on same window (required to create teaser video)
  var urlParams = new URLSearchParams(window.location.search);
  if ((process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'staging') && urlParams.get('id')) return '.' + urlParams.get('id');
  return '';
  // ---
};

export function getToken() {
  return getCookie(TokenKey + suffix(), {
    domain: process.env.VUE_APP_COOKIE_HOST
  });
}
export function setToken(token) {
  return setCookie(TokenKey + suffix(), token, {
    expires: 365,
    domain: process.env.VUE_APP_COOKIE_HOST
  });
}
export function removeToken() {
  return removeCookie(TokenKey + suffix(), {
    domain: process.env.VUE_APP_COOKIE_HOST
  });
}
var UserID = 'id';
export function getUserID() {
  return getCookie(UserID + suffix(), {
    domain: process.env.VUE_APP_COOKIE_HOST
  });
}
export function setUserID(id) {
  return setCookie(UserID + suffix(), id, {
    expires: 365,
    domain: process.env.VUE_APP_COOKIE_HOST
  });
}
export function removeUserID() {
  return removeCookie(UserID + suffix(), {
    domain: process.env.VUE_APP_COOKIE_HOST
  });
}