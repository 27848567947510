// ====== Helper ======
// 01.07.2019 using localStorage instead of Cookies because several Browsers (e.g. Chrome) don't support Cookies for file://
//            => electron / iOS not continiuing after login-screen
//            Problem:  https://stackoverflow.com/questions/15385641/javascript-code-for-cookie-not-working-in-chrome
//            Solution: https://stackoverflow.com/questions/34961345/android-devices-ionic-app-and-cookies

import Cookies from 'js-cookie';
export function getCookie(id) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
  var ret = Cookies.get(id, options);
  if (ret === undefined) {
    ret = window.localStorage.getItem(id);
  }
  return ret;
}

/**
 * Will set a cookie
 * 
 * @param {*} id 
 * @param {*} value 
 * @param {*} options e.g. {path: '', domain: '', validity: 7} (for details see https://github.com/js-cookie/js-cookie)
 */
export function setCookie(id, value) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var ret = Cookies.set(id, value, options);
  if (Cookies.get(id, options) !== value) {
    ret = window.localStorage.setItem(id, value);
  }
  return ret;
}
export function removeCookie(id) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
  var ret = Cookies.remove(id, options);
  ret = window.localStorage.removeItem(id);
  if (getCookie(id, options)) {
    // Make sure cookie is really deleted even if it was stored with an elder version
    Cookies.remove(id);
  }
  return ret;
}